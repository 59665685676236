import Lender from "../../services/lender";

export default {
  async submit({ state, commit }, payload) {
    const { data } = await Lender.submit(payload);
    return data;
  },
  async fetchAllLenders({ state, commit }) {
    const { data } = await Lender.fetchAllLenders();
    commit("storeAllLenders", data);
  },
};
