import axios from './index'

export default {

  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/roles/${id}`,data);
    }
    return axios.post('/roles', data)
  },
  fetch (id) {
    return axios.get(`/roles/${id}`)
  },
  delete(id){
    return axios.delete('/roles/'+id)
  },
  fetchRoles(payload)
  {
    return axios.get('/roles',{ params:{ ...payload }})
  },
  assignPermissions(data){
    return axios.post('/roles/assign_permissions',data)
  },
  fetchRolePermissions(id) {
      return axios.get(`/roles/role/permissions/${id}`)
  },

   fetchModels() {
        return axios.get('/roles/permission_models')
    },

  fetchAll()
  {
    return axios.get(`/roles/role/all`)
  }

}

