import axios from './index'

export default {

  submit (data) {
    return axios.post('/portfolio-settings', data)
  },
  fetch () {
    return axios.get(`/portfolio-settings`)
  },
}

