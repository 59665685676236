import axios from './index'

export default {
  submit (data) {
    if(data.id){
      const id=data.id;
      delete  data.id;
      return  axios.put(`tasks/${id}`,data);
    }
    return axios.post('/tasks', data)
  },
  fetch (payload) {
    return axios.get('/tasks',{params:{...payload}})
  },
  delete(id){
    return axios.delete('/tasks/'+id)
  },
  getTask(id){
    return axios.get(`/tasks/${id}`)
  },
  updateStatus(item)
  {
    return axios.post('tasks/update-status',item)
  }
}
