import {view} from "./../helper";

export default [
  {
    path: '/settings',
    name: 'app.settings',
    meta: { auth: true },
    component: view('Settings/Index')
  },
  {
    path: '/settings/users',
    name: 'app.users',
    meta: { auth: true, title: 'Users' },
    component: view('Settings/Users')
  },
  {
    path: '/settings/roles',
    name: 'app.roles',
    meta: { auth: true, title: 'Roles' },
    component: view('Settings/Roles')
  },
  {
    path: '/settings/permissions',
    name: 'app.permissions',
    meta: { auth: true, title: 'Permissions' },
    component: view('Settings/Permissions')
  },
  {
    path: '/settings/profile',
    name: 'app.profile',
    meta: { auth: true, title: 'Profile' },
    component: view('Settings/Profile')
  },

  {
    path: '/settings/2fa',
    name: 'app.twofactor',
    meta: { auth: true, title: 'Two FA Authentication' },
    component: view('Settings/2fa')
  },
  {
    path: '/settings/subscription',
    name: 'app.subscription',
    meta: { auth: true, title: 'My Subscription' },
    component: view('Settings/Subscription')
  },
  {
    path: '/settings/options',
    name: 'app.options',
    meta: { auth: true, title: 'Options' },
    component: view('Settings/Options')
  },
  {
    path: '/settings/portfolio-settings',
    name: 'app.portfolio_setting',
    meta: { auth: true, title: 'Portfolio Setting' },
    component: view('Settings/PortfolioSetting')
  },
]
