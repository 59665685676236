import store from '~/store'
import Cookies from 'js-cookie'

export default async (to, from, next) => {
  if (!store.getters['auth/check']) {
    Cookies.set('intended_url', to.path)
    next({ name: 'login' })
  } else {

    let user = store.getters['auth/user'];
    if (!user.email_verified_at && to.name != "email-verification") {
      next({name: 'email-verification'})
    }
    else if(!user.selected_portfolio && to.name != "portfolio-setting") {
      next({name: 'portfolio-setting'})
    }
    else if(user.selected_portfolio && to.name == "portfolio-setting") {
      next({name: 'profile-setting'})
    }
    else {
      next()
    }
  }
  next()

}
