
import Cookies from 'js-cookie'
export default {
  storeRole(state, data){

    state.role=data;
  },
  storeRoles(state, data){
    state.roles=data.data;
    state.params.total=data.total;
  },
  storeRolePermissions(state,data){
      state.rolePermissions=data;
  },
  storeModels(state,data){
        state.models=data;
    },
  storeAll(state,data)
  {
    state.all=data;
  }
}
