
import Cookies from 'js-cookie'
export default {
  storeSecurity(state, data){
    state.security=data.data;
  },
  storeSecurities(state, data){
    state.securities=data.data;

  },
}
