import Invitation from '../../services/invitations';


export default {
    async submit({state, commit}, payload){
        const { data } = await Invitation.accept(payload);
        return data;
    },
  async fetch({state, commit}){
    const { data } = await Invitation.fetch();
    commit('storeInvitations',data);
  },
}
