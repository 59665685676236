import Twofa from '../../services/2fa';

export default {

    async get({state, commit}) {
        const { data } = await Twofa.get();

        commit('store', {data});
    },
    async generate({state, commit}) {
        return Twofa.generate();
    },
    async enable({state, commit}, data) {
        return Twofa.enable(data);
    },
    async disable({state, commit}, data) {
        return Twofa.disable(data);
    },
    async  verify({state, commit}, data) {
        return Twofa.verify(data);
    },









}
