import Option from '../../services/option';
export default {
  async submit({state, commit}, payload){
    const { data } = await Option.submit(payload);
    return data;
  },
  async fetch({state, commit},type){
    const { data } = await Option.fetch(type);
    commit('storeOptions',{data,type});
  },
  async delete({state, commit},id){
    const { data } = await Option.delete(id);
    return data;
  },









}
