import axios from './index'

export default {

  submit (data) {
    let formData = new FormData();
    for ( const key in data ) {
      formData.append(key, data[key]);
    }
    if(data.id != null){
      formData.append('_method', 'PUT')
      return axios.post(`/property-securities/${data.id}`, formData)
    }
    return axios.post('/property-securities', formData)
  },
  fetch (id) {
    return axios.get(`/property-securities/${id}/security`)
  },
  delete(id){
    return axios.delete(`/property-securities/${id}`)
  },
  fetchSecurities(payload,id)
  {
    return axios.get(`/property-securities/${id}`,{params:{...payload}})
  },

}

