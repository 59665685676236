import Tenancy from '../../services/tenancy';
export default {
  async submit({state, commit}, payload){
    console.log(payload);
    const { data } = await Tenancy.submit(payload);
    return data;

  },
  async fetchTenancy({state, commit},id){
    const { data } = await Tenancy.fetchTenancy(id);
    commit('storeTenancy',data.data);
  },
  async fetchTenancies({state, commit},id){
    const payload=state.params;
    payload['property_id']=id;
    const { data } = await Tenancy.fetchTenancies({...payload});
    commit('storeTenancies',data);
  },
  async deleteTenancy({state, commit},id){
    const { data } = await Tenancy.deleteTenancy(id);
    return data;
  },








}
