import Vue from 'vue'
import VueOtp2 from 'vue-otp-2';
import '@babel/polyfill'
// import 'mutationobserver-shim'
// import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
// import Raphael from 'raphael/raphael'
import './plugins'
// import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
// import FileManager from 'laravel-file-manager'
import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';
import userAbility from "./Mixins/userAbility";
import VueToastr from "vue-toastr";

// Vue.use(AlgoliaComponents)
Vue.use(VueToastr)
// Vue.use(FileManager, {store})
Vue.use(VueOtp2);
Vue.use(abilitiesPlugin, ability);
Vue.mixin(userAbility);
Vue.config.productionTip = false

Vue.directive("email", {

  update(el, binding, vnode) {
    el.value = el.value.replace(/[^\w\s@,.]/gi, '');
    vnode.elm.dispatchEvent(new CustomEvent('input')); // added this

  }
})
Vue.directive("phone", {

  update(el, binding, vnode) {

    el.value = el.value.replace(/[^0-9+]/g, '');
    vnode.elm.dispatchEvent(new CustomEvent('input')); // added this

  }
})
Vue.directive("integer", {

  update(el, binding, vnode) {

    el.value = el.value.replace(/[^0-9]/g, '');
    vnode.elm.dispatchEvent(new CustomEvent('input')); // added this

  }
})

Vue.directive("currency", {

  update(el, binding, vnode) {

    el.value = el.value.match(/^\d+\.?\d{0,2}/);
    vnode.elm.dispatchEvent(new CustomEvent('input')); // added this

  }
})

Vue.directive("capitalize", {
  update(el, binding,vnode ) {
    el.value = el.value.charAt(0).toUpperCase() + el.value.slice(1);
    vnode.elm.dispatchEvent(new CustomEvent('input')); // added this

  }
})

let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
window.vm = vm
