import axios from './index'

export default {

  submit (data) {
    return axios.post('/lenders', data)
  },

  fetchAllLenders()
  {
        return axios.get('/lenders/all')
  }

}

