
import Cookies from 'js-cookie'
export default {
  storeExpense(state, data){
    state.expense=data.data;
  },
  storeExpenses(state, data){
    state.expenses=data.data
    // state.params.total=data.total;
  },
  storeFinancials(state,data)
  {
    state.financials=data;
  }
}
