import Customers from '../../services/customers';


export default {

  async fetchCustomers({state, commit}){
    const payload=state.params;
    const { data } = await Customers.fetch({...payload});
    commit('storeCustomers',data);
  },


}
