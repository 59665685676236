export default {
  user: state => state.user,
  id: state => state.user.id,
  check: state => state.user !== null,
  token: state => state.token,
  abilities: state => state.abilities,
  selected_portfolio: state => state.user.selected_portfolio,
  portfolio_name: state => state.user.portfolio_name,
  chosenPlan: state => state.chosenPlan
}
