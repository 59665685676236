import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  unit_types:[],
  tenant_types:[],
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
