import Expense from '../../services/expense';


export default {
  async submit({state, commit}, payload){
    const { data } = await Expense.submit(payload);
    return data;

  },
  async fetchExpenses({state, commit},id){
    const payload=state.params;
    const { data } = await Expense.fetchExpenses({...payload},id);
    commit('storeExpenses',data);
  },
  async fetchExpense({state, commit},id){
    const { data } = await Expense.fetch(id);
    commit('storeExpense',data);
  },
  async deleteExpense({state, commit},id){
    const { data } = await Expense.delete(id);
    return data;
  },
  async fetchFinancials({state,commit},payload)
  {
    const { data } = await Expense.financial(payload);
    commit('storeFinancials',data);
  }

}
