
import Cookies from 'js-cookie'
export default {
  saveLogin(state, {data, access_token, remember}){

    // state.user = JSON.parse(data);
    state.token = access_token;
    Cookies.set('token', access_token, { expires: remember ? 365 : null })
  },
  saveUser(state, data){

    state.user = data;

  },
  makeLogout(state, data){

    state.user = null
    state.token = null

    Cookies.remove('token')

  },
  saveAbilities(state, data){

    state.abilities = data

  },
  saveSelected(state,data){
    state.user.selected_portfolio=data;
  }
}
