
import Cookies from 'js-cookie'
export default {
  storeTenancy(state, data){
    state.tenancy=data;
  },
  storeTenancies(state,data){
    state.tenancies=data.data;
    state.params.total=data.total;

  }
}
