import axios from './index'

export default {
  submit (data) {

    let formData = new FormData();
    for ( const key in data ) {
      if(key === 'units')
      {
        for (const index  in data[key])
        {
          for(const detail in data[key][index])
          {
            formData.append(`units[${index}][${detail}]`,data[key][index][detail])
          }
        }
      }
      else if(key === 'references')
      {
        for (const index  in data[key])
        {
          for(const detail in data[key][index])
          {
            formData.append(`references[${index}][${detail}]`,data[key][index][detail])
          }
        }
      }
      else if(key === 'guarantors')
      {
        for (const index  in data[key])
        {
          for(const detail in data[key][index])
          {
            formData.append(`guarantors[${index}][${detail}]`,data[key][index][detail])
          }
        }
      }
      else {
        formData.append(key, data[key]);
      }
    }
    if(data.id){
      const id = data.id;
      delete data.id;
      formData.append('_method','PUT')
      return axios.post(`/properties/${id}`, formData)
    }
    return axios.post('/properties', formData)

  },
  fetchProperty (id) {
    return axios.get(`/properties/${id}`)
  },
  fetchProperties () {
    return axios.get('/properties')
  },
  deleteProperty(id){
    return axios.delete(`/properties/${id}`)
  },
  fetchPropertyStats(id){
    return axios.get(`/properties/${id}/stats`)
  },
  lettingAgents()
  {
    return axios.get(`/properties/letting-agents`)
  }
}
