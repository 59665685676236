import Vue from "vue";
import Vuex from "vuex";
import Setting from "./Setting/index";
import auth from "./auth/index";
import portfolio from "./portfolio/index";
import property from "./property/index";
import tenancy from "./tenancy/index";
import task from "./task/index";
import certificate from "./certificate/index";
import users from "./users/index";
import role from "./roles/index";
import permission from "./permissions/index";
import plan from "./plans/index";
import invite from "./invites/index";
import invitation from "./invitations/index";
import twofactor from "./2fa/index";
import subscription from "./subscription/index";
import customers from "./customers/index";
import units from "./units/index";
import tenants from "./tenants/index";
import options from "./options/index";
import expenses from "./expenses/index";
import documents from "./documents/index";
import feedbacks from "./feedbacks/index";
import portfolioSetting from "./portfolioSetting/index";
import alarms from "./alarms/index";
import security from "./security/index";
import references from "./references/index";
import utility from "./utility/index";
import agents from "./agents/index";
import lenders from "./lenders/index";
import { getField, updateField } from "vuex-map-fields";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Setting,
    auth,
    portfolio,
    property,
    tenancy,
    task,
    certificate,
    users,
    role,
    permission,
    plan,
    invite,
    invitation,
    twofactor,
    subscription,
    customers,
    units,
    tenants,
    options,
    expenses,
    documents,
    feedbacks,
    portfolioSetting,
    alarms,
    security,
    references,
    utility,
    agents,
    lenders,
  },
  state: {},
  mutations: {
    updateField,
  },
  actions: {},
  getters: {
    getField,
  },
  strict: debug,
});
