
import Cookies from 'js-cookie'
export default {
  storePlan(state, data){

    state.plan=data;
  },
  storePlans(state, data){
    state.plans=data.data;
    state.params.total=data.total;
  },
  storeAllPlans(state, data){
        state.plans=data;
    },
  resetPlan(state)
  {
    state.plan = null;
  }
}
