/* Layouts */

import { view, layout } from './helper'

import portfolios from "./routes/portfolios";
import properties from "./routes/properties";
import tenancies from "./routes/tenancies";
import financials from "./routes/financials";
import invitations from "./routes/share";
import payment from "./routes/payment";
import settings from "./routes/settings";
import customers from "./routes/customers";
import units from "./routes/units";
import expenses from "./routes/expenses";
import feedbacks from "./routes/feedbacks";

import guest from "./routes/guest";
import profile from "./routes/profile";

export default [
  {
    path: '/',
    name: '',
    component: layout('AppLayout'),
    meta: { auth: true },
    children: [
      ...portfolios,
      ...properties,
      ...tenancies,
      ...financials,
      ...invitations,
      ...payment,
      ...settings,
      ...customers,
      ...units,
      ...expenses,
      ...feedbacks,

    ]
  },
  {
    path: '/',
    name: '',
    component: layout('GuestLayout'),
    meta: { auth: true },
    children: [
      ...guest,
    ]
  },

    {
      path: '/',
       name: '',
       component: layout('ProfileLayout'),
       meta: { auth: true },
       children: [
         ...profile
       ]
     },
     {
      path: '/unauthorized',
      name: 'unauthorized',
      meta: { auth: true },
      component: view('Settings/404/404Page')
    },










]
