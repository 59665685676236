import Alarm from '../../services/alarm';


export default {
  async submit({state, commit}, payload){
    const { data } = await Alarm.submit(payload);
    return data;

  },
  async fetchAlarms({state, commit},id){
    const payload=state.params;
    const { data } = await Alarm.fetchAlarms({...payload},id);
    commit('storeAlarms',data);
  },
  async fetchAlarm({state, commit},id){
    const { data } = await Alarm.fetch(id);
    commit('storeAlarm',data);
  },
  async deleteAlarm({state, commit},id){
    const { data } = await Alarm.delete(id);
    return data;
  },





}
