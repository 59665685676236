import {view} from "./../helper";

export default [
  {
    path: 'properties/:pid/tenancies/add',
    name: 'app.tenancy.add',
    meta: { auth: true, title: 'Add Tenancy' },
    component: view('Portfolio/Property/Tenancies/Create')
  },
  {
    path: 'properties/:pid/tenancies/:id/edit',
    name: 'app.tenancy.edit',
    meta: { auth: true, title: 'Edit Tenancy' },
    component: view('Portfolio/Property/Tenancies/Create')
  },
]
