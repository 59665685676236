import axios from "./index";

export default {
  login(userData) {
    return axios.post("/login", userData);
  },

  register(userData) {
    return axios.post("/register", userData);
  },
  me() {
    return axios.get("/me");
  },
  abilities() {
    return axios.post("/abilities");
  },
  saveSelected(id) {
    return axios.post("/portfolios/selected_portfolio", id);
  },
  logout() {
    return axios.post("/logout", {});
  },
  update(data) {
    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return axios.post("/update", formData);
  },
  updatePassword(data) {
    return axios.post("/update_password", data);
  },
  resendEmail() {
    return axios.get("/resend-verification-email");
  },
  verifyEmail(data) {
    return axios.post("/verify-email", data);
  },
  sendResetLinkEmail(data) {
    return axios.post("/password/email", data);
  },
  reset(data) {

    const {email, password, password_confirmation, token} = data
    return axios.post(`/password/reset/${token}`, {email, password, password_confirmation, token});
  },
};
