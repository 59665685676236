import {view} from "./../helper";

export default [
  {
    path: '/feedbacks',
    name: 'app.feedbacks',
    meta: { auth: true , title: 'Feedbacks' },
    component: view('Feedback/Index')
  },
]
