import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  certificate: null,
  certificates: [],
  archived: [],
  params : {
    per_page : 0,
    current_page : 1,
    total : 0,
    keyword: null,
  },
  archived_params : {
    per_page : 5,
    current_page : 1,
    total : 0,
    keyword: null,
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
