import axios from './index'

export default {
  submit (data) {
    let formData = new FormData();
    for ( const key in data ) {
      if(key === 'meter_readings')
      {
        for (const index  in data[key])
        {
          for(const detail in data[key][index])
          {
            formData.append(`meter_readings[${index}][${detail}]`,data[key][index][detail])
          }
        }
      }
      else {
        formData.append(key, data[key]);
      }
    }
    if(data.id != null){
      formData.append('_method', 'PUT')
      return axios.post(`/utilities/${data.id}`, formData)
    }
    return axios.post('/utilities', formData)
  },
  fetch (payload) {
    console.log(payload);
    return axios.get('/utilities',{params:{...payload}})
  },
  delete(id){
    return axios.delete(`/utilities/${id}`)
  },
  get(id){
    return axios.get(`/utilities/${id}`)
  }
}
