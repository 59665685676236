import axios from './index'

export default {

  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/references/${id}`,data)
    }
    return axios.post('/references', data)
  },
  fetch (id) {
    return axios.get(`/references/${id}/reference`)
  },
  delete(id){
    return axios.delete(`/references/${id}`)
  },
  fetchReferences(id)
  {
    return axios.get(`/references/${id}`)
  }
}

