import Unit from '../../services/unit';
export default {

  async fetchUnits({state, commit},id){
    const payload=state.params;
    const { data } = await Unit.fetchUnits({...payload},id);
    commit('storeUnits',data);
  },
  async fetchUnit({state, commit},id){
    const { data } = await Unit.fetch(id);
    commit('storeUnit',data);
  },








}
