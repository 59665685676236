import axios from './index'

export default {
  submit(data) {
    if(data.id)
    {
      const id=data.id;
      delete data.id;
      return axios.put(`/tenancies/${id}`, data)
    }
    return axios.post('/tenancies', data)
  },
  fetchTenancy(id){
    return axios.get(`/tenancies/${id}`)
  },
  fetchTenancies(payload){
    return axios.get('/tenancies',{params:{...payload}});
  },
  deleteTenancy(id) {
    return axios.delete(`/tenancies/${id}`);
  }

}

