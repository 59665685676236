import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  property: null,
  property_stats: null,
  properties: [],
  letting_agents:[],
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
