
import Cookies from 'js-cookie'
export default {
  storePortfolios(state, data) {
    state.portfolios = data;
  },
  storePortfolio(state, data) {
    state.portfolio = data;
  }
}
