import axios from './index'

export default {

  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/property-alarms/${id}`,data)
    }
    return axios.post('/property-alarms', data)
  },
  fetch (id) {
    return axios.get(`/property-alarms/${id}/alarm`)
  },
  delete(id){
    return axios.delete(`/property-alarms/${id}`)
  },
  fetchAlarms(payload,id)
  {
    return axios.get(`/property-alarms/${id}`,{params:{...payload}})
  },

}

