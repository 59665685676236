import Security from '../../services/security';


export default {
  async submit({state, commit}, payload){
    const { data } = await Security.submit(payload);
    return data;

  },
  async fetchSecurities({state, commit},id){
    const payload=state.params;
    const { data } = await Security.fetchSecurities({...payload},id);
    commit('storeSecurities',data);
  },
  async fetchSecurity({state, commit},id){
    const { data } = await Security.fetch(id);
    commit('storeSecurity',data);
  },
  async deleteSecurity({state, commit},id){
    const { data } = await Security.delete(id);
    return data;
  },





}
