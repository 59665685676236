import Property from '../../services/property';
export default {
  async submit({state, commit}, payload){
    console.log(payload);
    const { data } = await Property.submit(payload);
    return data;

  },
  async fetchProperty({state, commit, rootState}, id){
    const { data } = await Property.fetchProperty(id);

    if (data.success) {
      commit('storeProperty',data.data);
      return true;
    }
    return false
  },
  async fetchProperties({state, commit}){
    const { data } = await Property.fetchProperties();
    commit('storeProperties',data.data);
  },
  async deleteProperty({state, commit},id){
    const { data } = await Property.deleteProperty(id);
    return data;
  },
  async fetchPropertyStats({state, commit},id){
    const { data } = await Property.fetchPropertyStats(id);
    commit('storePropertyStats',data);
  },
  async fetchLettingAgents({state,commit})
  {
    const { data } = await  Property.lettingAgents()
    commit("storeLettingAgents",data)
  }








}
