import {view} from "./../helper";

export default [
  {
    path: '/properties/:pid/expenses/add',
    name: 'app.expenses',
    meta: { auth: true , title: 'Add Expense' },
    component: view('Portfolio/Property/Expense/create')
  },
  {
    path: '/properties/:pid/expenses/:eid',
    name: 'app.expenses.edit',
    meta: { auth: true , title: 'Edit Expense' },
    component: view('Portfolio/Property/Expense/create')
  },
]
