import Utility from '../../services/utility';

export default {
  async submit({state, commit}, payload){
    const { data } = await Utility.submit(payload);
    return data;

  },
  async fetchUtilities({state, commit},id){
    const payload=state.params;
    payload['property_id']=id;
    const { data } = await Utility.fetch(payload);
    commit('storeUtilities',data);
  },
  async fetchUtility({state, commit},id){
    const { data } = await Utility.get(id);
    commit('storeUtility',data.data);
  },
  async deleteUtility({state, commit},id){
    const { data } = await Utility.delete(id);
    return data;
  },








}
