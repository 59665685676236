import Feedback from '../../services/feedback';

export default {
  async submit({state, commit}, payload){
    const { data } = await Feedback.submit(payload);
    return data;

  },
  async fetchFeedbacks({state, commit}){
    const payload=state.params;
    const { data } = await Feedback.fetch({...payload});
    commit('storeFeedbacks',data);
  },

  async updateStatus({state,commit},payload)
  {
    const { date }= await Feedback.updateStatus(payload);
    return date;
  }








}
