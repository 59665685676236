import axios from './index'

export default {

  fetch () {
    return axios.get(`/invitations`)
  },
  accept(payload)
  {
      return axios.post(`/invitations`,payload)
  }


}

