import Auth from '../../services/auth';
import Portfolio from "../../services/portfolio";
export default {
  async login({state, commit}, payload){
    let { data } = await Auth.login(payload);
    commit('saveLogin',data);
  },
    async register({state, commit}, payload){
        payload.plan=state.chosenPlan;
        console.log('plan',state.chosenPlan);
        const { data } = await Auth.register(payload);
        return {...data};
    },
  async fetchUser({state, commit}){
    const {data} = await Auth.me();
    commit('saveUser',data);
  },

  async fetchAbilities({state, commit}){
    const {data} = await Auth.abilities();
    commit('saveAbilities',data.data);

  },
  async saveSelected({state,commit},id){
    const { data } = await Auth.saveSelected(id);
    commit('saveSelected',data);
  },
  async logout({state, commit}){
      const {data} = await Auth.logout();
      commit('makeLogout',data);
    },
    async update({state, commit},payload){
        const {data} = await Auth.update(payload);
        return data;
    },
    async updatePassword({state, commit},payload){
        const {data} = await Auth.updatePassword(payload);
        return data;
    },
  async resendVerificationEmail({state, commit}){
    const {data} = await Auth.resendEmail();
    return data;
  },
  async verifyEmail({state, commit},payload){
    const {data} = await Auth.verifyEmail(payload);
    return data;
},
  async sendResetLinkEmail({state, commit},payload){
    const {data} = await Auth.sendResetLinkEmail(payload);
    return data;
},
  async reset({state, commit},payload){
    const {data} = await Auth.reset(payload);
    return data;
},


}
