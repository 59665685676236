import store from '~/store'
import Cookies from 'js-cookie'

export default async (to, from, next) => {
  if (!store.getters['auth/check']) {
    Cookies.set('intended_url', to.path)
    next({ name: 'login' })
  } else {

    let user = store.getters['auth/user'];
    if (user.message &&  to.name != '2fa') {
      next({name: '2fa'})
    } else if (!user.message && to.name == '2fa') {
      next({name: 'app.dashboard'})
    }
    else {
        next()
    }
  }
}
