
import Cookies from 'js-cookie'
export default {
  storeDocument(state, data){
    state.document=data.data;
  },
  storeDocuments(state, data){
    state.documents=data.data
    state.params.total=data.total;
  },
}
