
import Cookies from 'js-cookie'
export default {
  storeOptions(state,{ data,type}) {

    if(type == 'unit_types') {
      if(data !="") {
        state.unit_types = data
      }
    }
    if(type == 'tenant_types') {
      if(data !="") {
        state.tenant_types = data
      }
    }


  }
}
