import Task from '../../services/task';
import task from "../../services/task";

export default {
  async submit({state, commit}, payload){
    const { data } = await Task.submit(payload);
    return data;

  },
  async fetchTasks({state, commit},id){
    const payload=state.params;
      payload['property_id']=id;
    const { data } = await Task.fetch({...payload});
    commit('storeTasks',data);
  },
  async fetchTask({state, commit},id){
    const { data } = await Task.getTask(id);
    commit('storeTask',data.data);
  },
  async deleteTasks({state, commit},id){
    const { data } = await Task.delete(id);
    return data;
  },
async updateStatus({state,commit},payload)
  {
     const { data } = await  Task.updateStatus(payload)
     return data;
  }







}
