
import Cookies from 'js-cookie'
export default {
  storeProperty(state, data){

    state.property=data;
  },
  storeProperties(state, data){
    state.properties=data;
  },
  storePropertyStats(state, data){

    state.property_stats=data;
  },
  storeLettingAgents(state,data)
  {
    state.letting_agents=data;
  }
}
