import Agent from '../../services/agent';


export default {
  async submit({state, commit}, payload){
    const { data } = await Agent.submit(payload);
    return data;
  },
  async fetchAgents({state, commit}){
    const payload=state.params;
    const { data } = await Agent.fetchAgents({...payload});
    commit('storeAgents',data);
  },
   async fetchAllAgents({state, commit}){
        const { data } = await Agent.fetchAllAgents();
        commit('storeAllAgents',data);
    },
  async fetchAgent({state, commit},id){
    const { data } = await Agent.fetch(id);
    commit('storeAgent',data);
  },
  async deleteAgent({state, commit},id){
    const { data } = await Agent.delete(id);
    return data;
  }








}
