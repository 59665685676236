
import Cookies from 'js-cookie'
export default {
  storeCertificate(state, data){

    state.certificate=data;
  },
  storeCertificates(state, data){
    state.certificates=data.data;
    // state.params.total=data.meta.total;
  },
  storeArchived(state, data){
    state.archived=data.data;
    state.archived_params.total=data.meta.total;
  },
}
