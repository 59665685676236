import Plan from '../../services/plan';


export default {
  async submit({state, commit}, payload){
    const { data } = await Plan.submit(payload);
    return data;

  },
  async fetchPlans({state, commit}){
    const payload=state.params;
    const { data } = await Plan.fetchPlans({...payload});
    commit('storePlans',data);
  },
   async fetchAllPlans({state, commit}){
        const { data } = await Plan.fetchAllPlans();
        commit('storeAllPlans',data);
    },
  async fetchPlan({state, commit},id){
    const { data } = await Plan.fetch(id);
    commit('storePlan',data);
  },
  async deletePlan({state, commit},id){
    const { data } = await Plan.delete(id);
    return data;
  },
  resetPlan({state,commit})
  {
    commit('resetPlan');
  }








}
