import Role from '../../services/role';


export default {
  async submit({state, commit}, payload){
    const { data } = await Role.submit(payload);
    return data;

  },
  async fetchRoles({state, commit}){
    const payload=state.params;
    const { data } = await Role.fetchRoles({...payload});
    commit('storeRoles',data);
  },
  async fetchRole({state, commit},id){
    const { data } = await Role.fetch(id);
    commit('storeRole',data);
  },
  async deleteRole({state, commit},id){
    const { data } = await Role.delete(id);
    return data;
  },
  async assignPermissions({state, commit},payload){
    const { data } = await Role.assignPermissions(payload);
    return data;
  },
  async fetchRolePermissions({state, commit},id){
        const { data } = await Role.fetchRolePermissions(id);
        commit('storeRolePermissions',data);
    },
    async fetchModels({state, commit}){
        const { data } = await Role.fetchModels();
        commit('storeModels',data);
    },

  async fetchAll({state,commit})
  {
    const  { data } = await  Role.fetchAll()
    commit('storeAll',data);
  }








}
