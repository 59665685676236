import {view} from "./../helper";

export default [
  {
    path: '/property/:pid/unit/:uid',
    name: 'app.units',
    meta: { auth: true , title: 'Unit' },
    component: view('Portfolio/Property/Units/View')
  },
]
