import User from '../../services/user';

export default {

  async fetchUsers({state, commit}){
     const payload=state.params;
    const { data } = await User.fetchUsers({...payload});
    commit('storeUsers',data);
  },

  async submit({state, commit}, payload){
    const { data } = await User.submit(payload);
    return data;
  },

  async fetchUser({state, commit},id){
    const { data } = await User.fetch(id);
    commit('storeUser',data);
  },

  async deleteUser({state, commit},id){
    const { data } = await User.delete(id);
    return data;
  },


}
