
import Cookies from 'js-cookie'
export default {
  storeUtility(state, data){
    state.utility=data;
  },
  storeUtilities(state, data){
    state.utilities=data.data;
    // state.params.total=data.meta.total;
  },
}
