import axios from './index'

export default {

  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/plans/${id}`,data)
    }
    return axios.post('/plans', data)
  },
  fetch (id) {
    return axios.get(`/plans/${id}`)
  },
  delete(id){
    return axios.delete(`/plans/${id}`)
  },
  fetchPlans(payload)
  {
    return axios.get('/plans',{params:{...payload}})
  },
   fetchAllPlans()
    {
        return axios.get('/plans/all')
    }

}

