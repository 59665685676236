import axios from './index'

export default {

  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/agents/${id}`,data)
    }
    return axios.post('/agents', data)
  },

  fetch (id) {
    return axios.get(`/agents/${id}/agent`)
  },

  delete(id){
    return axios.delete(`/agents/${id}`)
  },

  fetchAgents(payload)
  {
    return axios.get('/agents',{params:{...payload}})
  },

  fetchAllAgents()
  {
        return axios.get('/agents/all')
  }

}

