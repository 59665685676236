import axios from './index'

export default {

  fetchTenants (payload) {
    return axios.get(`/tenants`,{params:{...payload}})
  },
  fetch(id){
    return axios.get(`/tenants/${id}`)
  },
}

