import {view} from "../helper";

export default [
  {
    path: 'portfolio-setting',
    name: 'portfolio-setting',
    meta: { auth: true },
    component: view('Auth/PortfolioSettings1')
  },
  {
    path: 'profile-setting',
    name: 'profile-setting',
    meta: { auth: true },
    component: view('Auth/ProfileSettings1')
  },
]
