import {view} from "./../helper";

export default [
  {
    path: '/invites',
    name: 'app.invites',
    meta: { auth: true, title: 'Collaborations' },
    component: view('Share/Invites')
  },
  {
    path: '/invitations',
    name: 'app.invitations',
    meta: { auth: true , title: 'Invitations' },
    component: view('Share/Invitations')
  },
]
