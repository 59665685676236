import store from '~/store'

/**
 * This is middleware to check the current user permission.
 *
 * middleware: 'permissions:access_dashboard,access_users',
 */

export default (to, from, next, permissions) => {

  // Grab the user
  const abilities = store.getters['auth/abilities']
  permissions = permissions.split(',')

  // Check if the user has all of the required permissions...
  if (!permissions.every(p => abilities.includes(p))) {
    next('/')
  }

  next()
}
