
import Cookies from 'js-cookie'
export default {
  storeLender(state, data){
    state.lender=data;
  },
  storeLenders(state, data){
    state.lenders=data.data;
    state.params.total=data.total;
  },
  storeAllLenders(state, data){
        state.all=data;
  },
}
