import {view} from "./../helper";

export default [
  {
    path: '/financials',
    name: 'app.financials',
    meta: { auth: true },
    component: view('Financials/Index')
  },
  {
    path: '/financials/tax',
    name: 'app.financials.tax',
    meta: { auth: true },
    component: view('Financials/Tax')
  },
  {
    path: '/financials/equity',
    name: 'app.financials.equity',
    meta: { auth: true },
    component: view('Financials/Equity')
  },
]
