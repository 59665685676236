import Document from '../../services/documents';


export default {
  async submit({state, commit}, payload){
    const { data } = await Document.submit(payload);
    return data;

  },
  async fetchDocuments({state, commit},id){
    const payload=state.params;
    payload['property_id']=id;
    const { data } = await Document.fetchDocuments({...payload});
    commit('storeDocuments',data);
  },
  async fetchDocument({state, commit},id){
    const { data } = await Document.fetch(id);
    commit('storeDocument',data);
  },
  async deleteDocument({state, commit},id){
    const { data } = await Document.delete(id);
    return data;
  },

}
