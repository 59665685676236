import {view} from "./../helper";

export default [
  {
    path: '',
    name: 'app.dashboard',
    meta: { auth: true , title: 'Portfolio' },
    component: view('Portfolio/Index')
  },
]
