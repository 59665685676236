
import Cookies from 'js-cookie'
export default {
  storeInvitation(state, data){

    state.invitation=data;
  },
  storeInvitations(state, data){
    state.invitations=data.data;
  },
}
