import axios from './index'

export default {

  fetchUnits (payload,id) {
    return axios.get(`/units/property/${id}`,{params:{...payload}})
  },
  fetch(id){
    return axios.get(`/units/${id}`)
  },
}

