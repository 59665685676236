
import Cookies from 'js-cookie'
export default {
  storeInvite(state, data){

    state.invite=data;
  },
  storeInvites(state, data){
    state.invites=data.data;
    },
}
