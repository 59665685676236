import axios from './index'
// import paraguayHigh from "@amcharts/amcharts4-geodata/paraguayHigh";

export default {

  fetchUsers(payload){
    return axios.get('/users',{params:{ ...payload }});
  },

  submit (data) {
    if(data.id){
      const id=data.id;
      return axios.put(`/users/${id}`,data);
    }
    return axios.post('/users', data)
  },

  fetch (id) {
    return axios.get(`/users/${id}`)
  },

  delete(id){
    return axios.delete('/users/'+id)
  },

}

