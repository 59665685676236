import Portfolio from '../../services/portfolio';
export default {
  async submit({state, commit}, payload){
    const { data } = await Portfolio.submit(payload);
    return data;
  },
  async fetch({state, commit}){
    const { data } = await Portfolio.fetch();
    commit('storePortfolios',data.data);
  },
  async getPortfolio({state, commit}){
    const { data } = await Portfolio.getPortfolio();
    commit('storePortfolio',data);
  },








}
