import axios from './index'

export default {

  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/invites/${id}`,data)
    }
    return axios.post('/invites', data)
  },
  fetch (id) {
    return axios.get(`/invites/${id}`)
  },
  delete(id){
    return axios.delete(`/invites/${id}`)
  },
  fetchInvites(){
        return axios.get(`/invites`)
    },
    changePermission(payload)
    {
        return axios.post('/invites/change_permission',payload);
    }
}

