
import Cookies from 'js-cookie'
export default {
  storeUnit(state, data){
    state.unit=data.data;
  },
  storeUnits(state, data){
    state.units=data.data;
    state.params.total=data.total
  },
}
