import axios from './index'

export default {

  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/permissions/${id}`,data)
    }
    return axios.post('/permissions', data)
  },
  fetch (id) {
    return axios.get(`/permissions/${id}`)
  },
  delete(id){
    return axios.delete(`/permissions/${id}`)
  },
  fetchPermissions(payload)
  {
    return axios.get('/permissions',{params:{...payload}})
  }

}

