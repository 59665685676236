
import Cookies from 'js-cookie'
export default {
  storeAgent(state, data){
    state.agent=data;
  },
  storeAgents(state, data){
    state.agents=data.data;
    state.params.total=data.total;
  },
  storeAllAgents(state, data){
        state.all=data;
  },
}
