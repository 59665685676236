import {view} from "./../helper";

export default [
  {
    path: '/properties/add',
    name: 'app.property.add',
    meta: { auth: true, title: 'Add Property' },
    component: view('Portfolio/Property/Create')
  },
  {
    path: '/properties/:id/edit',
    name: 'app.property.edit',
    meta: { auth: true, title: 'Edit Property' },
    component: view('Portfolio/Property/Create')
  },
  {
    path: '/properties/:id',
    name: 'app.property',
    meta: { auth: true, title: 'Property' },
    component: view('Portfolio/Property/Index')
  },
]
