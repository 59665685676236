import Reference from '../../services/reference';
export default {

  async fetchReferences({state, commit},id){
    const { data } = await Reference.fetchReferences(id)
    commit('storeReferences',data);
  },
  async fetchReference({state, commit},id){
    const { data } = await Reference.fetch(id);
    commit('storeReference',data);
  },








}
