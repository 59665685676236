import axios from './index'

export default {
  submit (data) {
    return axios.post('/options', data)
  },
  fetch (type) {
    return axios.get(`/options/${type}`)
  },
  delete(id){
    return axios.delete(`/options/${id}`)
  },

}
