
import Cookies from 'js-cookie'
export default {
  storeAlarm(state, data){
    state.alarm=data;
  },
  storeAlarms(state, data){
    state.alarms=data;
  },
}
