import axios from './index'

export default {

  submit (data) {

    let formData = new FormData();
    for ( const key in data ) {
      formData.append(key, data[key]);
    }
    if(data.id){
      const id=data.id;
      formData.append('_method','PUT')
      return axios.post(`/documents/${id}`,formData);
    }
    return axios.post('/documents', formData)
  },
  fetch (id) {
    return axios.get(`/documents/${id}/document`)
  },
  delete(id){
    return axios.delete('/documents/'+id)
  },
  fetchDocuments(payload)
  {
    return axios.get(`/documents`,{ params:{ ...payload }})
  },

}

