import axios from './index'

export default {
  submit (data) {
    if(data.id){
      const id=data.id;
      delete data.id;
      return axios.put(`/portfolios/${id}`,data)
    }
    return axios.post('/portfolios', data)
  },
  fetch () {
    return axios.get('/portfolios')
  },
  fetchSelected(){
    return axios.get('/portfolios/selected_portfolio')
  },
  getPortfolio(){
    return axios.get(`/portfolios/portfolio`);
  },

}
