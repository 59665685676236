import {view} from "./../helper";

export default [
  {
    path: 'login',
    name: 'login',
    meta: { auth: true },
    component: view('Auth/SignIn1')
  },
  {
    path: 'login1',
    name: 'login1',
    meta: { auth: true },
    component: view('Auth/SignIn')
  },
  {
    path: 'signup',
    name: 'signup',
    meta: { auth: true },
    component: view('Auth/SignUp1')
  },
  {
    path: 'signup1',
    name: 'signup1',
    meta: { auth: true },
    component: view('Auth/SignUp')
  },
  {
    path: 'verification',
    name: 'verification',
    meta: { auth: true },
    component: view('Auth/Verification1')
  },
  {
    path: '2fa',
    name: '2fa',
    meta: { auth: true },
    component: view('Auth/OTP')
  },
  {
    path: "/email/verify/:id",
    name: "verification.verify",
    meta: { auth: true },
    component: view("Auth/EmailVerified"),
  },
  {
    path: 'email-verification',
    name: 'email-verification',
    meta: { auth: true },
    component: view('Auth/Verification1')
  },
  {
    path: '/auth/password-request',
    name: 'password.email',
    meta: { auth: true },
    component: view('Auth/ResetPasswordEmail1')
  },
  {
    path: '/password/reset/:token',
    name: 'password.reset',
    meta: { auth: true },
    component: view('Auth/ResetPassword1')
  },
]
