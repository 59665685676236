import axios from './index'

export default {
  submit (data) {
    let formData = new FormData();
    for ( const key in data ) {
        formData.append(key, data[key]);
    }
    if(data.id != null){
      formData.append('_method', 'PUT')
      return axios.post(`/certificates/${data.id}`, formData)
    }
    return axios.post('/certificates', formData)
  },
  fetch (payload) {
    return axios.get('/certificates',{params:{...payload}})
  },
  fetchArchived(payload)
  {
    return axios.get('/certificates/archived',{params:{...payload}})
  },
  delete(id){
    return axios.delete(`/certificates/${id}`)
  },
  getCertificate(id){
    return axios.get(`/certificates/${id}`)
  }
}
