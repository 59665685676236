
import Cookies from 'js-cookie'
export default {
  storeReference(state, data){
    state.reference=data.data;
  },
  storeReferences(state, data){
    state.references=data.data;
  },
}
