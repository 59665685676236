export default {
  methods: {
     $cando(permission){
       const permissions=this.$store.state.auth.abilities;
         if(permissions.includes(permission)){
         return true;
       }else{
         return false;
       }
     }
    }
  }
