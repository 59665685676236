import Invite from '../../services/invite';


export default {
  async submit({state, commit}, payload){
    const { data } = await Invite.submit(payload);
    return data;

  },
  async fetchInvites({state, commit}){
    const { data } = await Invite.fetchInvites();
    commit('storeInvites',data);
  },
    async fetchInvite({state, commit},id){
        const { data } = await Invite.fetch(id);
        commit('storeInvite',data);
    },
    async delete({state, commit},id){
        const { data } = await Invite.delete(id);
        return data;
    },
    async changePermission({state, commit},payload){
        const { data } = await Invite.changePermission(payload);
        return data;
    },

}
