import axios from './index'

export default {

  submit (data) {
      return axios.post('/subscription',data);
  },
  fetch () {

    return axios.get(`/subscription`)

  },
  cancel(id) {
    return axios.delete(`/subscription/${id}/cancel`)
  }

}

