import {view} from "./../helper";

export default [

  {
    path: '/payments',
    name: 'app.payments',
    meta: { auth: true },
    component: view('Others/Data/Payment')
  },
  {
    path: '/plans',
    name: 'app.plans',
    meta: { auth: true, title: 'Subscriptions' },
    component: view('Plans/Index')
  },
]
