
import Cookies from 'js-cookie'
export default {
  storeFeedback(state, data){
    state.feedback=data;
  },
  storeFeedbacks(state, data){
    state.feedbacks=data.data;
    state.params.total=data.total;
  },
}
