import axios from './index'

export default {
  submit (data) {
    return axios.post('/feedbacks', data)
  },
  fetch (payload) {
    return axios.get('/feedbacks',{params:{...payload}})
  },
  updateStatus(data)
  {
    return axios.post(`/feedbacks/status`,data);
  }
}
