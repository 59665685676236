import Subscription from '../../services/subscription';


export default {
  async submit({state, commit}, payload){
    const { data } =await Subscription.submit(payload);
    return data;
  },

  async fetch({state, commit}){
    const { data } = await Subscription.fetch();
    commit('storeSubscription',data);
  },
  async cancel({commit,state},id)
  {
    const {data} = await  Subscription.cancel(id);
    return data;
  }
}
