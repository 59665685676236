
import Cookies from 'js-cookie'
export default {
  storePermission(state, data){

    state.permission=data;
  },
  storePermissions(state, data){
    state.permissions=data.data;
    state.params.total=data.total;
  },
}
