import Certificate from '../../services/certificate';

export default {
  async submit({state, commit}, payload){
    const { data } = await Certificate.submit(payload);
    return data;

  },
  async fetchCertificates({state, commit},id){
    const payload=state.params;
    payload['property_id']=id;
    const { data } = await Certificate.fetch(payload);
    commit('storeCertificates',data);
  },
  async fetchArchived({state, commit},id){
    const payload=state.archived_params;
    payload['property_id']=id;
    const { data } = await Certificate.fetchArchived(payload);
    commit('storeArchived',data);
  },
  async fetchCertificate({state, commit},id){
    const { data } = await Certificate.getCertificate(id);
    commit('storeCertificate',data.data);
  },
  async deleteCertificate({state, commit},id){
    const { data } = await Certificate.delete(id);
    return data;
  },








}
