import Tenant from '../../services/tenat';
export default {

  async fetchTenants({state, commit}){
    const payload=state.params;
    const { data } = await Tenant.fetchTenants({...payload});
    commit('storeTenants',data);
  },
  async fetchTenant({state, commit},id){
    const { data } = await Tenant.fetch(id);
    commit('storeTenant',data);
  },








}
