import PortfolioSetting from '../../services/portfolioSetting';


export default {
  async submit({state, commit}, payload){
    const { data } = await PortfolioSetting.submit(payload);
    return data;
  },
  async fetchSetting({state, commit}){
    const { data } = await PortfolioSetting.fetch();
    commit('storeSetting',data);
  }








}
