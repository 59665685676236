
import Cookies from 'js-cookie'
export default {
  storeUser(state, data){
    state.user=data;
  },
  storeUsers(state, data){
    state.users=data.data;
    state.params.total=data.total;
  },
}
