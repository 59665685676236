
import Cookies from 'js-cookie'
export default {
  storeTenant(state, data){
    state.tenant=data.data;
  },
  storeTenants(state, data){
    state.tenants=data.data;
    state.params.total=data.total
  },
}
