import Permission from '../../services/permission';


export default {
  async submit({state, commit}, payload){
    const { data } = await Permission.submit(payload);
    return data.success;

  },
  async fetchPermissions({state, commit}){
    const payload=state.params;
    const { data } = await Permission.fetchPermissions({...payload});
    commit('storePermissions',data);
  },
  async fetchPermission({state, commit},id){
    const { data } = await Permission.fetch(id);
    commit('storePermission',data);
  },
  async deletePermission({state, commit},id){
    const { data } = await Permission.delete(id);
    return data.success;
  },








}
