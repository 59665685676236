import axios from './index'

export default {

  fetch (payload) {
    return axios.get(`/customers`,{params:{...payload}})
  },

}

