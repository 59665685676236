import axios from './index'

export default {

  submit (data) {

    let formData = new FormData();
    for ( const key in data ) {
      formData.append(key, data[key]);
    }
    if(data.id){
      const id=data.id;
      formData.append('_method','PUT')
      return axios.post(`/expenses/${id}`,formData);
    }
    return axios.post('/expenses', formData)
  },
  fetch (id) {
    return axios.get(`/expenses/${id}/expense`)
  },
  delete(id){
    return axios.delete('/expenses/'+id)
  },
  fetchExpenses(payload,id)
  {
    return axios.get(`/expenses/${id}`,{ params:{ ...payload }})
  },
  financial(payload)
  {
    return axios.get(`/financials`,{params:{...payload}})
  }

}

