
import Cookies from 'js-cookie'
export default {
  storeTask(state, data){

    state.task=data;
  },
  storeTasks(state, data){
    state.tasks=data.data;
    state.params.total=data.meta.total;
  },
}
