import {view} from "./../helper";

export default [
  {
    path: '/customers',
    name: 'app.customers',
    meta: { auth: true, title: 'SaaS' },
    component: view('Customers/Index')
  },
]
